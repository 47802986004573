import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ReactNode, useState } from 'react';

type Props = {
   title: string;
   children: ReactNode;
};

export default function PopoverHover({ title, children }: Props) {
	const [isShowing, setIsShowing] = useState(false);
	
   return (
      <Popover className="relative">
         <PopoverButton className="flex items-center" onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
            {title}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-5 h-5">
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
               />
            </svg>
         </PopoverButton>
         <Transition
            open={isShowing}
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
         >
            <PopoverPanel className="absolute z-10 mt-2 p-4 bg-white border border-primary-500 rounded-md text-primary-700">{children}</PopoverPanel>
         </Transition>
      </Popover>
   );
}
